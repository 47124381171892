import { Component, OnInit } from '@angular/core';
import {TypewriterComponent} from '../../shared/typewriter/typewriter.component';

@Component({
  selector: 'app-intro-section',
  templateUrl: './intro-section.component.html',
  styleUrls: ['./intro-section.component.css']
})
export class IntroSectionComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
