<div class="container">
  <div class="heading-container">
    <h1>
      <app-typewriter autostart="true" delay="75" to_write="// About Me"></app-typewriter>
    </h1>
    <p>I ♥ robots!</p>
  </div>
  <div class="content">
    <div class="parallax-wrapper">
      <app-webp appParallax [ratio]="-0.08" alt="Headshot of Mihir Lad" name="headshot"></app-webp>
    </div>
    <div class="description">
      <p>Hi, I'm Mihir Lad, an innovative Software Engineer with a wide breadth of experience in Robotics.
        I love technology and making things from woodwork to software, especially when others can enjoy the final
        product as much as I do. Anything that requires efficient design, logical thinking, and creativity is right up
        my alley.</p>
      <p>I've been programming since 10 years old and developing robots since 16.
      During my college career, I attained many achievements including qualifying for the VEX Robotics World
      Championship multiple times and leading a team to successfully organize a 24-hour Hackathon.
      I've also held 5 executive-level positions in 3 different student organizations.</p>
      <p>I graduated from <a href="https://floridapoly.edu">Florida Polytechnic University</a>, earning a Bachelor's Degree
      in Computer Engineering with a concentration in Autonomous Robotic Systems.
      For over a year now, I've been leading the development of the guidance software (involving perception,
      planning, and control) for an autonomous medical robot.</p>
      <p>Robotics is my passion and with my specialized experience in mechanical, electrical, and
        software engineering combined with my leadership experience, I am determined to improve the world through
        robotics and automation.</p>
    </div>
  </div>
</div>
